*{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}
.cont-sider{
    border-radius: 10px;
    
}

label{
    margin-bottom: 0;
}

.btn-menu{
    display: none;
    padding: 20px;
    background: #cfd6db;
    color: #fff;
}

.btn-menu .icono {
    float: right;
}

.contenedor-menus{
    width: 100%;
    margin-left: 4px;
    /* min-width: 265px; */
    display: inline-block;
    line-height: 16px;

}

.menu{
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
}

.item {
    border-top: 1px solid #028d7b;
    overflow: hidden;
}

.btn{
    display: block;
    padding: 10px 7px;
    background: #028d7b;
    color: #fff;
    position: relative;
    text-align: left;   
    cursor: pointer;
}

/* .btn::before{
    content:"";
    position: absolute;
    width: 12px;
    height: 12px;
    background: #028d7b;
    left: 12px;
    bottom: -7px;
    transform: rotate(45deg);
} */

.btn1{
    display: block;
    padding: 10px 7px;
    background: #028d7b;
    color: #38eb47;
    position: relative;
    text-align: center;   
    font-size: 20px;    
}


.icono.izquierda {
    float: left;
    margin-right: 10px;
}
.icono.derecha {
    float: right ;
    margin-left: 10px;
}

.icono-derecha-up {
    float: right ;
    margin-left: 10px;
 }

 .submenu{
    margin-left: 10px;
    color: #38eb47;
 }

 .sssmenu{
    background: #08cfb8;
    overflow: hidden;
    transition: max-height 0.7s;
    max-height: 0;    
    margin-left: 40px;
}

.sssmenu a{
    display: block;
    padding: 10px 16px;
    color: #fff;
    font-size: 14px;
    margin: 1px 0;
    position: relative;
    text-decoration: none;
   
}

.sssmenu a::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    background: #065249;
    left: 0;
    top: 0;
    opacity: 0;
}
.sssmenu a:hover::before {
    opacity: 1;

}

 .ssmenu{
    background: #08cfb8;
    overflow: hidden;
    transition: max-height 0.7s;
    max-height: 0;
}

.ssmenu a{
    display: block;
    padding: 10px 16px;
    color: #fff;
    font-size: 14px;
    margin: 1px 0;
    position: relative;
    text-decoration: none;
   
}

.ssmenu a::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    background: #065249;
    left: 0;
    top: 0;
    opacity: 0;
}

.ssmenu a:hover::before {
    opacity: 1;

}

.smenu{
    background: #12b5a1;
    overflow: hidden;
    transition: max-height 0.7s;
    max-height: 0;    
}

.smenu a{
    display: block;
    padding: 10px 16px;
    color: #fff;
    font-size: 14px;
    margin: 1px 0;
    position: relative;
    text-decoration: none;
   
}


.smenu a::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    background: #12b5a1;
    left: 0;
    top: 0;
    opacity: 0;
}
.smenu a:hover::before {
    opacity: 1;

}

#acordion1:checked ~ .smenu, #acordion2:checked ~ .smenu, #acordion3:checked ~ .smenu, #acordion4:checked ~ .smenu, #acordion5:checked ~ .smenu{
    max-height: 40em;    
}

#sacordion1:checked ~ .ssmenu, #sacordion2:checked ~ .ssmenu, #sacordion3:checked ~ .ssmenu, #sacordion4:checked ~ .ssmenu, #sacordion5:checked ~ .ssmenu{
    max-height: 40em;    
}

#ssacordion1:checked ~ .sssmenu, #ssacordion2:checked ~ .sssmenu, #ssacordion3:checked ~ .sssmenu, #ssacordion4:checked ~ .sssmenu, #ssacordion5:checked ~ .sssmenu{
    max-height: 40em;    
}

#acordion1, #acordion2, #acordion3, #acordion4, #acordion5 {
    display: none;
    position: relative;
}

#sacordion1, #sacordion2, #sacordion3, #sacordion4, #sacordion5 {
    display: none;
    position: relative;    
}

#ssacordion1, #ssacordion2, #ssacordion3, #ssacordion4, #ssacordion5 {
    display: none;
    position: relative;    
}

@media screen and (max-width: 768px) {

        .contenedor-menu{
            margin: 0;
            width: 100%;
            position: fixed;
            top: 10;
            z-index: 1000;
        }
        .btn-menu {
            display: block;
        }
        .contenedor-menu .menu{
            display: none;
        }

}

