/* .principal1{
    position:absolute;
    overflow: hidden; 
    width: 1350px;
    height: 780px;
}
.contenedorFrame{
    margin-block-start: -60px;
    width: 1300px;
} */
.encima {
    /* width: 1278px;
    height: 780px;
    display: inline-block;
    margin: 0;
    padding: 10px;
    position: absolute;
    z-index: 2;
    background-color: transparent; */
    /* background-color: #FF0000; */
}

.principal1 {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.principal1 iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* #myIframe {  
    resize: both;
    
  } */
