.cont-datoscasos {
  background-color: #028d7b;
  height: 490px;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);  
  margin-bottom: 15px;
}
.cont-titulocasos, .cont-titulomedi{
  text-align: center;
}
.titulocasos{
  font-size: 25px;
  color: #028d7b;
}

.titulo-cerrarcasos {
  font-size: 30px;
  color: rgb(255, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font: bold;
  float: right;
  cursor: pointer;
}

.accionescasos{
  margin: 0px 0px 15px 15px;
  flex: 0%;
  text-align: center;
}
.titulooferta {
  color: #fff;
  font-size: 18px;
}
.botonescasos {
  margin-top: 40px;
}
.botoncasosOff {
  color: rgba(182,184,214,1);
  background: transparent;
  outline: none;
  border: solid 1px rgb(18, 149, 167);;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px; 
  margin-right: 20px; 
}    
.botoncasos {
  color: #028d7b;
  background-color: #fff;
  outline: none;
  border: solid 2px aquamarine;
  font-size: 16px;
  padding: 5px 5px;
  border-radius: 10px; 
  margin-right: 20px; 
}
.botoncasos:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)
}
.cont-opcioncas1 {
  margin-left: 20px;
}


.cont-tablamedi{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;  
  align-Content: center;
  justify-Content: center;
  z-index:10;
}
.tablamedi1{
  background-color: #18ccb4;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1); 
  align-Self: flex-start;
  width: 100%; 
  margin-bottom: 15px;
}


.cont-listmedi {
  color: rgb(7, 5, 5);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listmedi {
  height: 280px;
  width: 100%;
  width: auto;
  overflow: auto;
}

.tablame {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;  
}

.cerrarme1{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    cursor: pointer;
    margin-top: 2px;
  }
  .cerrarme3{
    background-color: #fc1515;
    background-image: url('../../imagenes/borrar.png');
    background-size: 20px;
    background-repeat: no-repeat; 
    width: 25px;
    height: 25px;
    background-position:center;
    font-size: 14px;
    padding: 1px 1px;
    border-radius: 5px;
    display:inline-block ;
    margin-top: 2px;
  }
  .titulo-casos {
    color: #fff;
    font-size: 20px;
    font: bold;
    margin-left: 100px;
    margin-right: 220px;
  }
  .titulo-eliminar {
    color: #fff;
    font-size: 20px;
    font: bold;
    margin-left: 150px;
  }
  #tabla {
    cursor: pointer;
  }
  #codigo2, #descripcion {
    height: 40px;
    font-size: 15px;
    margin-top: 30px;
  }

  .cont-check {
    text-align: center;
  }  

  .cont-opcion22c {
    margin-bottom: 15px;
    margin-left: 50px ;
    text-align: center;
    color: #fff;
    font-size: 15px;
    margin-right: 10px;
  }

  .opcion2c{
    color: rgb(14, 13, 13);
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    width: 170px;
  }

  #alumnos, #docente {
    font-size: 4px;
    text-decoration: none;
    width: 100%;
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    
  }

  
.radio {
  margin-left: 10% !important;
}
.container {
  font-size: 15px !important;
}
  
.opnivel2 {
  font-size: 20px;
  margin-left: 20px;
}
.cont-opcion2 {
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  width: 200px;
} 
.opcion {
  color: #fff;
  font-size: 14px;
  text-align: center;  
  display: block;
  padding-right: 0px;
  padding-left: 22px;
  text-indent: -22px;
  margin: -20px;
}
#coordina, #docente, #alumnos, #primerT, #segundoT, #tercerT {
  /* width: 13px; */
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -16px;
  *overflow: hidden;
}
  