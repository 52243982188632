h1{
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
  }
   
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
}
.cont-footer-princ {
 left: 30px !important; 
}

.contenedor-pago {
  width: 100%;
  height: 550px !important;
  background-color: #e7f3ce
}

.contenedor-cedula  {
    width: 100%;
    margin: 0px;
    
  }
  .contenedor-titulo {
    display: contents;
    height:200px;
  }
  .titulo-pago {
    line-height: 50px;
    background-color: #028d7b;
    font-size: 15px;
    text-align: center;
    color: #050505;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 15px;


  }

  .labelcedulac  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    
  }
  .contenedor-cedula, .contenedorcedula1, .contenedorcedula2, .cont-radio {
    text-align: center;
      display: inline-block;
  }
  
  .contenedorcedula1{
      margin-top: 20px;
  }
  .labelcedulac1  {
    font-size: 18px;
    color: #050505;
    font-weight: bold;
    margin-right: 20px;
    
  }
  .opnivel2 {
    font-size: 20px;
    margin-left: 20px;
  }

  .boton {
    color: #070707;
    background-color: #fff;
    outline: none;
    border: solid 2px#C8C8C8;
    font-size: 16px;
    padding: 5px 5px;
    border-radius: 10px; 
    font-weight: bold;
    margin-bottom: 5%;

  }
.boton:hover {
  color:  #fff;
  background-color: rgb(35, 80, 69)

}
.transfe{
  margin-right: 15px;
}


.cont-tablamedi{
  color: rgb(7, 5, 5);
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;  
  align-Content: center;
  justify-Content: center;
  z-index:10;
}
.tablamedi1{
  background-color: #18ccb4;
  -webkit-box-shadow: 0px 1px 1px 5px rgba(182,184,214,1);
  -moz-box-shadow: 0px 1px 1px 5px rgb(182,184,214,1);
  box-shadow: 0px 1px 1px 5px rgba(182,184,214,1); 
  align-Self: flex-start;
  width: 100%; 
  margin-bottom: 15px;
}


.cont-listmedi {
  color: rgb(134, 133, 133);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.listmedi {
  height: 280px;
  width: 100%;
  width: auto;
  overflow: auto;
}
.cont-titulomedi{
  text-align: center !important;
}

.cont-titulocasos{
  text-align: center !important;
  margin-top: 20px;
}

.titulo-casos {
  color:#028d7b;
  font-size: 25px;
  font: bold;
  margin-right: 120px;
}

.titulocasos{
  font-size: 25px;
  color: #040808 ;
}
.cont-titulomonto{
  color:#028d7b;
  font-size: 25px;
  font: bold;
}
.titulomonto{
  float: right;
  font-size: 25px;
  color: #040808;
}
#boton1{
  margin-right: 40px;
}
#boton2{
  margin-right: 40px;
}

.titulo-cerrarcasos {
  font-size: 30px;
  color: rgb(255, 0, 0);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font: bold;
  float: right;
  cursor: pointer;
}
.opcion {
  color: #fff;
  font-size: 14px;
  text-align: left;  
}
.accionescasos{
  margin: 0px 0px 15px 15px;
  flex: 0%;
  text-align: center;
}

.tablame {
  color: #fff;
  background-color: #7ac5cd;
  width: 100%;
  border: 1px solid #7ac5cd;
  font-size: 16px;
  border-collapse: collapse;
  /* margin: 0 0 1em 0; */
  caption-side: top;
  border-collapse: collapse;  
}
.odd {
  background-color: #11a794;
}
.odb {
  background-color: #099784;
}
.flotador4xf {
  margin-top: -20px;
  position: absolute;
  height: 80%;
  width: 98%;
  align-content: center;
  justify-content: center;
  z-index: 10;
  background-color: #2bbbad;
}